.input {
  &.input-slider {
    min-height: 70px;
    padding-bottom: 1rem;

    .input-description {
      margin-bottom: 1rem;
    }

    .noUi-target {
      border: 1px solid rgb(var(--color-secondary));
      border-radius: var(--border-radius-small);
      background: transparent;
      height: 1rem;
      //padding: 0 15px;
      box-shadow: none;
      margin: 10px 50px 0 35px;

      .noUi-base {
        border: none;
        background: transparent;
        box-shadow: none;

        .noUi-handle {
          border: none;
          border-radius: var(--border-radius-small);
          background: rgb(var(--color-primary));
          height: 1.5rem;
          cursor: grab;
          box-shadow: 0 0 8px rgb(var(--color-shadow) / 20%);

          &::before, &::after {
            display: none;
          }

          &:active {
            cursor: grabbing;
          }
        }
      }

      .noUi-pips {
        //display: flex;
        //width: calc(100% + 30px);
        //height: 2.5rem;
        //padding: .75rem 0;
        //margin: 0 0 0 -15px;
        //position: relative;
        //top: 0;
        padding: 0;

        .noUi-marker {
          display: none;
        }

        .noUi-value {
          color: rgb(var(--color-body-font));
          position: absolute;
          //transform: none;

          // values after first
          & ~ .noUi-value ~ .noUi-value {
            //transform: translateX(-50%);
          }

          //// last value
          //&:last-child {
          //	transform: none;
          //	left: unset !important;
          //	right: 0;
          //}
        }
      }
    }

    &.error {
      .noUi-target {
        .noUi-base {
          .noUi-handle {
            background: rgb(var(--color-error));
          }
        }
      }
    }
  }
}