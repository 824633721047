/** VARIABLES */
/**********************************************/

$section-margin: 1.5rem;

$font-awesome-font-weights-by-style: (
	'thin': 100,
	'light': 300,
	'regular': 400,
	'solid': 900,
);

/** MIXINS */
/**********************************************/

@mixin font-awesome($icon, $style) {
	content: $icon;
	font-family: 'Font Awesome 6 Pro';
	font-weight: map-get($font-awesome-font-weights-by-style, quote($style)) or $style;
}

/** DEFAULTS */
/**********************************************/

* {
	padding: 0;
	margin: 0;
	box-sizing: border-box;
}

:root {
	--border-radius-small: .3125rem;
	--border-radius-big: .625rem;

	--color-background: 255 255 255;
	--color-background-alt: 242 244 245;
	--color-surface: 255 255 255;
	--color-error: 253 77 53;
	--color-primary: 0 179 229;
	--color-primary-on: 255 255 255;
	--color-secondary: 0 0 0;
	--color-secondary-on: 255 255 255;
	--color-body-font: 0 0 0;

	--font-family-body: system-ui;
	--font-size-body: 16px;

	font-size: var(--font-size-body);
}

body {
	font-family: var(--font-family-body);
	color: rgb(var(--color-body-font));
	line-height: 1.2;
	width: 100%;
	overflow-x: hidden;
	padding: 5px;
}

p {
	margin-bottom: 1em;

	&:last-child {
		margin-bottom: 0;
	}
}

a {
	font: 1rem 550 normal;
	text-decoration: none;
	color: rgb(var(--color-primary));
}

ul, ol {
	list-style-position: inside;
	margin-bottom: 1em;

	&:last-child {
		margin-bottom: 0;
	}

	li {
		margin: .25em 0;

		ul, ol {
			padding-left: 1.5em;
			width: 100%;
		}
	}
}

ul {
	list-style: none;

	li {
		&::before {
			content: '';
			vertical-align: baseline;
			background: currentColor;
			border-radius: 999px;
			display: inline-block;
			width: .625em;
			height: .625em;
			margin-right: .5em;
		}
	}
}

#customerwrapper {
	ul {
		li {
			&::before {
				content: none;
			}
		}
	}
}



h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6 {
	margin: 1rem 0 .5rem;

	&:first-child {
		margin-top: 0;
	}

	&:last-child {
		margin-bottom: 0;
	}

	&.no-margin {
		margin: 0;
	}
}

h1, .h1 {
	font-size: 26px
}

h2, .h2 {
	font-size: 22px
}

h3, .h3 {
	font-size: 20px
}

h4, .h4 {
	font-size: 16px
}

h5, .h5 {
	font-size: 14px
}

h6, .h6 {
	font-size: 12px
}

input {
	&[type="checkbox"], &[type="radio"] {
		appearance: none;
		border: 1px solid rgb(var(--color-secondary));
		background-color: rgb(var(--color-surface));
		display: inline-block;
		width: 1.25rem;
		height: 1.25rem;
		position: relative;
		overflow: hidden;

		&::after {
			content: '';
			display: block;
			position: absolute;
			opacity: 0;
		}

		&:hover {
			&::after {
				opacity: .5;
			}
		}

		&:checked {
			&::after {
				opacity: 1;
			}
		}

		&[type="checkbox"] {
			border-radius: var(--border-radius-small);

			&::after {
				border: solid rgb(var(--color-primary));
				border-width: 0 0 4px 4px;
				width: 120%;
				height: 50%;
				top: -10%;
				left: -10%;
				transform: rotate(-45deg);
			}
		}

		&[type="radio"] {
			border-radius: 999px;

			&::after {
				border-radius: 999px;
				background: rgb(var(--color-primary));
				width: 68%;
				height: 68%;
				top: 50%;
				left: 50%;
				transform: translate(-50%, -50%);
			}
		}
	}
}

.text-subtle {
	color: rgb(var(--color-body-font) / 60%);
}

.visually-hidden {
	white-space: no-wrap;
	width: 1px;
	height: 1px;
	position: absolute;
	overflow: hidden;
	clip: rect(0 0 0 0);
	clip-path: inset(50%);
}

/** LAYOUT */
/**********************************************/

.flex {
	display: flex;

	&.row { flex-direction: row; }
	&.column { flex-direction: column; }

	&.gap-small { gap: .5rem; }
	&.gap-medium { gap: 1rem; }
	&.gap-large { gap: 1.5rem; }

	&.wrap { flex-wrap: wrap; }
	&.wrap-reverse { flex-wrap: wrap-reverse; }
}

.module {
	display: flex;
	flex-direction: column;
	gap: 1.5rem;
}

.fields-container {
	display: flex;
	flex-direction: column;
	align-items: flex-start;

	> * + * {
		margin-top: 1.5rem;
	}
}

.button-row {
	display: flex;
	gap: .5rem;
	align-items: stretch;
}

.user-container {
	margin-bottom: 1.5rem;

	* + & {
		margin-top: 1.5rem;
	}
}

.info-block {
	margin: $section-margin 0;

	&:first-child {
		margin-top: 0;
	}

	&.last-child {
		margin-bottom: 0;
	}
}

.app-info {
	font-size: .75em;
	color: rgb(var(--color-body-font) / 60%);
	margin-top: 1.5rem;

	a {
		border: none;
		color: inherit;
	}
}

.button {
	--button-gap: 0.625rem;
	--button-top-padding: 0.313rem;
	--button-icon-size: 1.5rem;

	font-size: 1rem;
	background: transparent;
	color: inherit;
	border: none;
	box-shadow: none;
	text-align: left;
	text-shadow: none;
	outline: none;

	display: flex;
	align-items: center;
	justify-content: space-between;
	gap: var(--button-gap);
	cursor: pointer;
	line-height: 1;
	position: relative;
	z-index: 1;

	.content {
		flex: 1;
		text-align: center;
	}

	&.secondary {
		&:hover, &:focus {
			text-decoration: underline;
		}
	}

	&.primary {
		padding: var(--button-top-padding) var(--button-gap);
		border: 1px solid rgb(var(--color-secondary));
		border-radius: var(--border-radius-small);

		.icon-container {
			font-size: 1.5em;
			border: 0 solid rgb(var(--color-secondary));
		}

		&.icon-right .icon-container {
			border-left-width: 1px;
			padding-left: var(--button-gap);
		}

		&.icon-left .icon-container {
			border-right-width: 1px;
			padding-right: var(--button-gap);
		}

		&:hover, &:focus {
			text-decoration: none;
		}
	}

	&:disabled {
		opacity: .5;
		cursor: default;

		&::before {
			display: none;
		}
	}
}

.button-back {
	margin-bottom: 1.5rem;
}

.button-next {
	margin-top: 2rem;
}

.button-alternative {
	margin-top: .75rem;
}

.react-tabs {
	.react-tabs__tab-list {
		// border-radius: var(--border-radius-small);
		// border: 1px solid rgb(var(--color-secondary));
		display: flex;
		flex-direction: row;
		flex-wrap: wrap;
		width: auto;
		margin: $section-margin 0 1rem;

		.react-tabs__tab {
			font-size: 1rem;
			border-radius: var(--border-radius-small);
			padding: 0.625rem;
			position: relative;
			overflow: hidden;
			list-style: none;
			cursor: pointer;
			outline: none;

			& + .react-tabs__tab {
				margin-left: 0.3125rem;
			}

			&.react-tabs__tab--selected {
				background: rgb(var(--color-primary));
				color: rgb(var(--color-primary-on));
			}
		}
	}
}

.collapsable {
	transition: height .2s ease-out;
	overflow: hidden;
}

.collapsable-button-container {
	display: flex;
	flex-direction: column;
	align-items: stretch;

	.collapsable-button {
		font-size: 1rem;
		background: transparent;
		border: none;
		display: flex;
		align-items: center;
		justify-content: center;
		padding: .5rem 1rem;
		cursor: pointer;

		.collapsable-button-icon {
			font-size: 1.25em;
			margin-right: .5em;
		}

		.collapsable-button-label {
			font-size: 1em;
		}
	}

	&.collapsed {
		.collapsable-button-icon {
			transform: rotate(180deg);
		}
	}
}


.input {
	font-size: 1rem;
	display: flex;
	flex-direction: column;
	width: 100%;
	max-width: 660px;

	.input-label {
		font-weight: 600;
		margin-bottom: .3125rem;
		cursor: pointer;
	}

	.input-description {
		margin-top: .3125rem;
		color: rgb(var(--color-body-font) / 60%);
	}

	.input-element {
		font-size: 1rem;
		background: rgb(var(--color-surface));
		color: inherit;
		box-shadow: none;
		text-align: left;
		text-shadow: none;
		outline: none;
		display: block;
		padding: 0.625rem 0.625rem;
		border: 1px solid rgb(var(--color-secondary));
		border-radius: var(--border-radius-small);
		transition:
			border linear 0.2s,
			box-shadow linear 0.2s;

		&::placeholder {
			color: rgb(var(--color-body-font) / 60%);
		}

		&:focus {
			border-color: rgb(var(--color-primary));
			box-shadow: 0 0 8px rgb(var(--color-primary) / 60%);
		}
	}

	&.error {
		color: rgb(var(--color-error));

		.input-element:focus {
			border-color: rgb(var(--color-error));
			box-shadow: 0 0 8px rgb(var(--color-error) / 60%);
		}
	}


	&.input-captcha {
		.input-captcha-inner-container {
			display: flex;
			align-items: center;
			border: 1px solid rgb(var(--color-secondary));
			border-radius: var(--border-radius-small);

			& > :first-child { // captcha image container
				display: flex;
				align-items: center;
				border-right: 1px solid rgb(var(--color-secondary));
			}

			.input-element {
				flex: 1;
				border: none;
				border-radius: 0 var(--border-radius-small) var(--border-radius-small) 0;
			}
		}

		&.error {
			color: rgb(var(--color-error));

			.input-element:focus {
				border-color: rgb(var(--color-error));
				box-shadow: 0 0 8px rgb(var(--color-error) / 60%);
			}
		}
	}

	&.input-slider {
		min-height: 90px;
		padding-bottom: 4rem;

		.input-description {
			margin-bottom: 1rem;
		}

		&.error {
			.noUi-target {
				.noUi-base {
					.noUi-handle {
						background: rgb(var(--color-error));
					}
				}
			}
		}
	}

	&.input-checkboxes, &.input-radio, &.input-checkbox {
		.option, &.input-checkbox {
			display: grid;
			grid-template-columns: min-content 1fr;

			& + .option {
				margin-top: .5rem;
			}
		}

		.option-label {
			cursor: pointer;
			padding-left: .625em;
		}

		.option-input {
			cursor: pointer;
		}

		.input-description {
			grid-row: 2;
			grid-column: 2;
			margin-left: .625em;
		}
	}

	&.input-select {
		&.error {
			.select-control.focused {
				border-color: rgb(var(--color-error));
				box-shadow: 0 0 8px rgb(var(--color-error) / 60%);
			}
		}
	}

	&.input-file {
		.input-file-button {
			align-self: flex-start;
		}
	}
}

.select-container {
	color: inherit;

	.select-control, .select-control:hover {
		border-color: rgb(var(--color-secondary));
		box-shadow: none;
		cursor: text;
		transition:
			border linear 0.2s,
			box-shadow linear 0.2s;

		.select-placeholder {
			color: rgb(var(--color-body-font) / 60%);
		}

		.select-indicator-seperator {
			background-color: rgb(var(--color-secondary));
		}

		.select-indicator-container {
			cursor: pointer;

			.select-indicator-clear, .select-indicator-dropdown {
				color: rgb(var(--color-body-font));

				&:hover {
					opacity: .5;
				}
			}
		}

		&.focused {
			border-color: rgb(var(--color-primary));
			box-shadow: 0 0 8px rgb(var(--color-primary) / 60%);
		}
	}

	.select-menu {
		box-shadow: 0 0 16px rgb(var(--color-shadow) / 20%);
		color: rgb(var(--color-body-font));
		z-index: 999;

		.select-option-list {
			border-radius: var(--border-radius-small);

			.select-option {
				cursor: pointer;

				&.focused {
					background-color: rgb(var(--color-primary) / 15%);
				}

				&.selected {
					background-color: rgb(var(--color-primary));
					color: rgb(var(--color-primary-on));
				}
			}
		}
	}
}

.appointment {
	.appointment-duration {
		font-weight: 600;
		display: block;
		margin-bottom: 1rem;
	}
}

.date-time-list {
	display: flex;
	flex-direction: column;
	gap: 1.5rem;
	max-width: 100%;

	.year {
		.year-heading {
			font-size: 2.25rem;
			text-align: center;
		}
	}

	.weeks {
		display: flex;
		flex-direction: column;
		gap: 2rem;

		.week {
			border-radius: var(--border-radius-big);
			background-color: rgb(var(--color-secondary) / 15%);
			padding: .625rem;

			.week-heading {
				font-size: 1.25rem;
				text-align: center;
				margin-bottom: .625rem;
			}
		}
	}

	.days {
		display: flex;
		flex-direction: column;
		gap: 1rem;

		.day {
			border-radius: var(--border-radius-big);
			background: rgb(var(--color-surface));
			padding: 24px;
			box-shadow: 0 0 8px rgb(var(--color-shadow) / 20%);

			.collapsable-button {
				display: none;
			}
		}
	}

	.times {
		display: flex;
		gap: .5rem;
		overflow-x: auto;

		.time {
			flex: 0 0 auto;
			background: rgb(var(--color-surface));

			.icon-container {
				display: none;
			}

			.content {
				white-space: nowrap;
			}
		}
	}
	.date-time-list-selected {
		border-radius: var(--border-radius-big);
		background: rgb(var(--color-secondary) / 15%);
		font-weight: 600;
		font-size: 1.5rem;
		text-align: center;
		padding: .625rem;
	}
}

@media screen and (max-width: 659px) {
	.date-time-list {
		.days {
			.day {
				.collapsable.collapsed {
					height: auto !important;
				}
			}
		}
	}
}

@media screen and (min-width: 660px) {
	.date-time-list {
		.days {
			.day {
				.collapsable {
					min-height: 5.5rem;
				}

				.collapsable-button {
					display: flex;
				}
			}
		}

		.times {
			display: grid;
			grid-template-columns: repeat(4, min-content);
			overflow: hidden;

			.time {
				.icon-container {
					display: block;
				}
			}
		}
	}
}

.table {
	border-radius: var(--border-radius-big);
	background: rgb(var(--color-secondary) / 15%);
	display: inline-block;
	width: auto;
	max-width: 100%;
	padding: 1rem;
	overflow-x: auto;

	.table-element {
		display: block;
		border-collapse: collapse;

		.table-row {
			& + .table-row {
				border-top: 1px solid rgb(var(--color-secondary));
			}

			.table-cell {
				vertical-align: top;
				padding: .3125rem .625rem;


				& + .table-cell {
					margin-left: .5rem;
				}
			}
		}

		.table-head {
			.table-cell {
				font-weight: 600;
			}
		}

		.table-foot {
			border-top: 3px solid rgb(var(--color-secondary));

			.table-row {
				.table-cell {
					&:first-child {
						font-weight: 600;
					}
				}
			}
		}
	}
}

.payment {
	.payment-table {
		margin-bottom: 2rem;

		.table-body {
			.table-row:last-child {
				.table-cell {
					margin-bottom: 1rem;
				}
			}
		}

		.table-foot {
			.table-cell:last-child {
				text-align: right;
			}
		}
	}
}

.download {
	.download-button {
		margin-top: .75rem;
	}
}

.freetext {
	.freetext-image {
		max-width: 660px;
	}
}

.paypal {
	.paypal-buttons {
		max-width: none;
	}
}

.evaluation {
	.evaluation-chart {
		max-width: 660px;
	}

	.recharts-legend-item {
		&::before {
			display: none;
		}
	}

	#chart-rendering-wrapper {
		display: block;
		position: absolute;
		left: -100vw;
		z-index: 1;
	}

	#evaluation-content-wrapper {
		position:relative;
		background: white;
		z-index: 2;
	}
}

.module-branch {
	.branches {
		display: flex;
		flex-direction: column;
		gap: 1rem;

		.branch {
			border-radius: var(--border-radius-big);
			background-color: rgb(var(--color-secondary) / 15%);
			padding: 1rem;
		}
	}
}

@media only screen and (min-width: 840px) {
	.module-branch {
		.branches.horizontal {
			display: grid;
			grid-template-columns: repeat(2, 1fr);
		}
	}
}

#loading-wrapper {
	background-color: rgb(255 255 255 / 75%);
	display: flex;
	width: 100%;
	height: 100%;
	position: fixed;
	top: 0;
	left: 0;
	z-index: 999;
	cursor: default;
}
#loading-wrapper:after {
	content: '';
	border-radius: 50%;
	border: 5px solid;
	border-color: rgb(var(--color-primary)) transparent;
	display: block;
	width: 50px;
	height: 50px;
	margin: auto;
	animation: spin 1s infinite linear;
}



/** ANIMATION */
/**********************************************/

@keyframes spin {
	from {
		transform: rotate(0);
	}
	to {
		transform: rotate(360deg);
	}
}